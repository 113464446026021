








































import { defineComponent, ref } from "@vue/composition-api";
import SelectFilter from "@monorepo/uikit/src/components/common/Select/SelectFilter.vue";
import TextFilter from "@monorepo/uikit/src/components/tableViews/TextFilter.vue";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/syncLogOikView/filtersElements";
import { currentFilters as setCurrentFilters } from "../../utils/defaultCurrentFilters";
import { mapActions, mapGetters } from "vuex";
import { getStatuses } from "../../utils/getStatuses";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/syncLogOikView/viewTitle";
import { fields } from "@monorepo/utils/src/variables/projectsData/syncLogOikView/fields";
import FindButton from "@monorepo/uikit/src/components/tableViews/FindButton.vue";
import ToggleFilters from "@monorepo/uikit/src/components/tableViews/ToggleFilters.vue";
import ClearFilters from "@monorepo/uikit/src/components/tableViews/ClearFilters.vue";
import Autocomplete from "@monorepo/uikit/src/components/tableViews/Autocomplete.vue";
import { debounce } from "lodash";
import { defaultFilterPlaceholder } from "@monorepo/utils/src/constants";
import eventBus from "@monorepo/utils/src/eventBus";
import { SYNC_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/syncEvents";
import useFilter from "@monorepo/utils/src/composables/useFilter";
import useAutocomplete from "@monorepo/utils/src/composables/useAutocomplete";

export default defineComponent({
  components: {
    SelectFilter,
    TextFilter,
    FindButton,
    ToggleFilters,
    ClearFilters,
    Autocomplete,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    sectionName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      filtersElements,
      viewUniqKey,
      fields,
      defaultFilterPlaceholder,
    };
  },
  computed: {
    ...mapGetters("syncLogOikView", ["logStatusesLib", "isTableLoading", "isLoadingToggleFilters"]),
    ...mapGetters("syncLogOikView", { fieldFilters: "oikFieldFilters" }),
  },
  methods: {
    ...mapActions("syncLogOikView", ["clearFilters", "getOikAutocompleteItems"]),
    ...mapActions("syncLogOikView", { refreshScroll: "refreshOikScroll", addFilter: "addOikFilter" }),
    setCurrentFilters,
    clearFiltersComponentCb: debounce(
      function (this: { clearFiltersCb: () => void }) {
        this.clearFiltersCb();
      },
      1000,
      { leading: true, trailing: false }
    ),
    refreshComponentCb: debounce(
      function (this: { refresh: () => void }) {
        this.refresh();
      },
      1000,
      { leading: true, trailing: false }
    ),
    changeStatus(status: string) {
      this.currentFilters[fields.STATUS] = [];
      this.currentFilters[fields.STATUS] = (this.currentFilters[fields.STATUS] as string[]).concat(getStatuses(status));
      this.currentFilters[fields.DATE_FROM] = `${new Date().getFullYear()}-01-01`;
      this.currentFilters[fields.DATE_TO] = "";
    },
  },
  beforeMount() {
    eventBus.$on(SYNC_EVENT_BUS_ACTIONS.CHANGE_STATUS, this.changeStatus);
  },
  beforeDestroy() {
    eventBus.$on(SYNC_EVENT_BUS_ACTIONS.CHANGE_STATUS, this.changeStatus);
  },
  setup(props, context) {
    const { root } = context;
    const currentFilters = ref((setCurrentFilters as () => Record<string, unknown>)());
    const notHiddenKeys = ref([] as string[]);
    const isShowToggle = ref(false);
    const autocompleteItemsResultLists = ref({} as { [key: string]: Record<string, unknown>[] });
    const isLoadingAutocomplete = ref(false);
    const { filter, hiddenRowsStyleObj, openHiddenFilterOnStart, toggleOpenFilter, clearFiltersCb, refresh, updateFiltersDivHeight } = useFilter(
      context,
      props as {
        value: boolean;
      },
      props.sectionName,
      {
        refreshScroll: "refreshOikScroll",
        addFilter: "addOikFilter",
        fieldFilters: "oikFieldFilters",
      },
      currentFilters,
      setCurrentFilters,
      filtersElements as { value: string; title: string }[],
      notHiddenKeys,
      isShowToggle
    );
    const { getAutocompleteItems } = useAutocomplete(root, props.sectionName, currentFilters, autocompleteItemsResultLists, isLoadingAutocomplete, [
      fields.FILTER_OIK_CODE,
    ]);

    return {
      filter,
      hiddenRowsStyleObj,
      openHiddenFilterOnStart,
      toggleOpenFilter,
      clearFiltersCb,
      refresh,
      updateFiltersDivHeight,
      currentFilters,
      notHiddenKeys,
      isShowToggle,
      getAutocompleteItems,
      autocompleteItemsResultLists,
      isLoadingAutocomplete,
    };
  },
});
